import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { Link } from 'gatsby';
import { getImage, GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import PostInformation from './PostInformation';

const propTypes = {
  uri: string.isRequired,
  date: string.isRequired,
  author: shape({}).isRequired,
  title: string.isRequired,
  featuredImage: shape({}).isRequired,
  excerpt: string.isRequired,
  categories: arrayOf(shape({})).isRequired,
};

const Post = ({
  uri,
  date,
  author,
  title,
  featuredImage,
  excerpt,
  categories,
}) => {
  const titleParsed = parse(title);
  const featuredImageObj = {
    image: getImage(featuredImage?.node?.localFile),
    alt: featuredImage?.node?.altText || ``,
  };

  return (
    <div className="c-blog-post-summary">
      <article itemScope itemType="http://schema.org/Article">
        <header>
          <PostInformation
            author={author}
            date={date}
            categories={categories}
          />
          <h2 className="h4 mb-3">
            <Link to={uri} itemProp="url" className="text-dark fw-bold">
              <span itemProp="headline">{titleParsed}</span>
            </Link>
          </h2>
        </header>
        <section itemProp="description">
          <Row>
            <Col lg={4}>
              {featuredImageObj.image ? (
                <GatsbyImage
                  image={featuredImageObj.image}
                  alt={featuredImageObj.alt}
                />
              ) : (
                <StaticImage
                  src="../../images/default-blog-image.png"
                  width="225"
                  alt={titleParsed}
                />
              )}
            </Col>
            <Col lg={8}>
              {parse(excerpt)}
              <Link to={uri} itemProp="url" className="text-secondary fw-bold">
                Read more
              </Link>
            </Col>
          </Row>
        </section>
      </article>
    </div>
  );
};

Post.propTypes = propTypes;

export default Post;
