import React from 'react';
import { shape, string, number } from 'prop-types';
import Pagination from 'react-bootstrap/Pagination';

const propTypes = {
  pageContext: shape({}).isRequired,
  path: string.isRequired,
  suffixPath: string,
  siblings: number,
};

const defaultProps = {
  suffixPath: 'page',
  siblings: 2,
};

const Paginator = ({
  pageContext: {
    previousPagePath,
    nextPagePath,
    humanPageNumber,
    numberOfPages,
  },
  path,
  suffixPath,
  siblings,
}) => {
  const siblingsPages = Array.from({ length: siblings }, (_, i) => i + 1);
  const pagePath = path + suffixPath;

  return (
    <Pagination>
      {previousPagePath && <Pagination.Prev href={previousPagePath} />}

      {humanPageNumber !== 1 && (
        <Pagination.Item href={path}>1</Pagination.Item>
      )}

      {humanPageNumber - siblings - 1 > 1 && <Pagination.Ellipsis />}

      {[...siblingsPages].reverse().map((page) => {
        const pageToDraw = humanPageNumber - page;

        if (pageToDraw > 1) {
          return (
            <Pagination.Item
              key={pageToDraw}
              href={`${pagePath}/${pageToDraw}`}
            >
              {pageToDraw}
            </Pagination.Item>
          );
        }

        return null;
      })}

      <Pagination.Item active>{humanPageNumber}</Pagination.Item>

      {siblingsPages.map((page) => {
        const pageToDraw = humanPageNumber + page;

        if (pageToDraw <= numberOfPages) {
          return (
            <Pagination.Item
              key={pageToDraw}
              href={`${pagePath}/${pageToDraw}`}
            >
              {pageToDraw}
            </Pagination.Item>
          );
        }

        return null;
      })}

      {humanPageNumber + siblings + 1 < numberOfPages && (
        <Pagination.Ellipsis />
      )}

      {humanPageNumber + siblings < numberOfPages && (
        <Pagination.Item href={`${pagePath}/${numberOfPages}`}>
          {numberOfPages}
        </Pagination.Item>
      )}

      {nextPagePath && <Pagination.Next href={nextPagePath} />}
    </Pagination>
  );
};

Paginator.propTypes = propTypes;
Paginator.defaultProps = defaultProps;

export default Paginator;
