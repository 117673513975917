import React from 'react';
import { string } from 'prop-types';
import { Link } from 'gatsby';

const propTypes = {
  image: string.isRequired,
  name: string.isRequired,
  uri: string.isRequired,
  description: string.isRequired,
};

const AuthorBox = ({ image, name, uri, description }) => {
  return (
    <div className="c-blog-author-box">
      <img
        src={image}
        alt={name}
        width={60}
        height={60}
        className="c-blog-author-box--image"
      />

      <div className="c-blog-author-box--info">
        <h5 className="mb-1 fw-bold">
          <Link to={uri} className="text-body">
            {name}
          </Link>
        </h5>
        <p>{description}</p>
      </div>
    </div>
  );
};

AuthorBox.propTypes = propTypes;

export default AuthorBox;
